import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
import {DomIf} from '@polymer/polymer/lib/elements/dom-if.js';
import '@banno/platform-ux-shared/components/polymer3/jha/toasts/jha-toast-container.js';
import '@banno/platform-ux-shared/components/polymer3/jha/toasts/jha-toast.js';
import '@banno/platform-ux-shared/components/polymer3/jha/progress/jha-progress.js';
import store from '@banno/platform-ux-shared/session-storage/store.js';
import InstitutionsController from '@banno/platform-ux-shared/controllers/institutions-controller.js';
import UserAccessController from '@banno/platform-ux-shared/controllers/user-access-controller.js';
import AuthController from '@banno/platform-ux-shared/controllers/auth-controller.js';
import BrowserCheckService from '@banno/platform-ux-shared/services/browser-check/browser-check-service.js';
import dashboardRouteTree from '../js/routing/routes.js';
import RouteIds from '../js/routing/id.js';
import RoutePaths from '../js/routing/paths.js';
import router from '@banno/platform-ux-shared/services/router-jh-wcr.js';
import {routingMixin as RouterMixin} from '@jack-henry/web-component-router';
const browserCheck = BrowserCheckService.getInfo();
const toastMessages = {
  toastSetPassword401: 'Your password token has expired. Request a new password to get a new token.',
  toastSetPassword400: 'Your password token is either invalid or your passwords do not pass security requirements.',
  toastSetPasswordInvalidToken: 'Your password token is either missing or invalid.',
  toastSetPasswordReset: 'Your password has been updated!',
  toastSetPasswordLogin: 'Your password is set! Now you can log in.',
  toastSetPasswordBadOldpassword: 'The old password you entered does not match our password on file.',
  toastSetPasswordMustLogin: 'You need to be logged in to reset you password.',
  toastInstitutionsFailedLoad: 'There was an error retrieving your institutions.',
  toastInstitutionsFailedSet: 'There was an error setting your selected institution.',
  toastInstitutionsNone: 'Sorry, but no institutions were found.',
};

/**
 * @constructor
 * @polymer
 * @extends {PolymerElement}
 */
const DashboardAppBase = RouterMixin(PolymerElement);
/** @polymer */
class DashboardAppElement extends DashboardAppBase {
  static get is() {
    return 'dashboard-app';
  }
  static get properties() {
    return {
      isLoading: {
        type: Boolean,
        value: false,
      },
    };
  }

  connectedCallback() {
    super.connectedCallback();

    router.routeTree = dashboardRouteTree;
    // Define this instance as the root element
    router.routeTree.getValue().element = this;
    // Start routing
    router.start();
    this.addEventListener('logout', (e) => {
      this._logout();
    });
    this.addEventListener('toast', (e) => {
      this._toast(e);
    });
  }

  async routeEnter(currentNode, nextNodeIfExists, routeId, context, next) {
    context.handled = true;

    if (!browserCheck.browserAllowed && RouteIds.BROWSER_UPGRADE !== routeId) {
      await import(/* webpackChunkName: "browser_upgrade" */ './browser-upgrade/browser-upgrade.js');
      this.isLoading = false;
      next(false);
      router.go(RoutePaths.BROWSER_UPGRADE);
      return;
    }
    let firstSelectedInstitution;
    let institutions;
    const destinationNode = router.routeTree.getNodeByKey(routeId);
    if (routeId === RouteIds.DASHBOARD_APP || destinationNode.requiresAuthentication()) {
      let user = store.user;
      if (!user || !user.hasOwnProperty('internal')) {
        try {
          const session = await this._getSession(next);
          user = session.user;
        } catch (e) {
          console.error(e);
        }
      }
      if (user && (!store.user || store.user.userId !== user.userId || !store.user.hasOwnProperty('internal'))) {
        store.user = user;
        store.institutions = undefined;
      }
      if (user && user.userId) {
        const session = await this._getSession(next);
        const highRisk = session.highRisk;
        if (highRisk.enabled && !highRisk.verified) {
          this._redirectToLogin(next);
          return;
        }

        if (!(user.firstName && user.lastName)) {
          router.go('/a/settings/profile');
          next(false);
          return;
        }
        try {
          institutions = store.institutions;
          if (!institutions) {
            institutions = await InstitutionsController.loadInstitutions(user.userId);
            user = store.user;
          }
        } catch (e) {
          this._redirectToLogin(next);
          return;
        }
        try {
          if (!user.internal) {
            const mailboxesKey = institutions.map((institution) => ({
              institutionId: institution.institution.institutionId,
              privilege: 'read',
              application: 'mailboxes',
              resource: 'conversations',
              resourceId: '*',
              property: '*',
              key: '1',
            }));
            const mailboxPermission = await UserAccessController.validatePermissions(mailboxesKey);
            if (mailboxPermission.some((permission) => permission.result === 'allowed')) {
              if (!user.bio || !user.location) {
                router.go('/a/settings/profile');
                next(false);
                return;
              }
            }
          }
        } catch (e) {
          this._redirectToLogin(next);
          return;
        }
      } else {
        this._redirectToLogin(next);
        return;
      }
      store.user = user;
      store.institutions = institutions;
    }
    switch (routeId) {
      case RouteIds.LOGIN: {
        let user = store.user;
        if (!user) {
          try {
            user = await UserAccessController.isLoggedIn();
          } catch (e) {
          }
        }
        if (user) {
          const session = await this._getSession(next);
          const highRisk = session.highRisk;
          if (highRisk.enabled && !highRisk.verified) {
            setTimeout(() => {
              const loginContainer = this.querySelector('login-container');
              loginContainer.email = session.user.email;
              loginContainer.user = session.user;
              if (!highRisk.enrolled || !highRisk.verified) {
                loginContainer._logicSuccess(session.user);
              }
            }, 1);
            break;
          }
          if (context.query.get('redirect_to')) {
            router.go(context.query.get('redirect_to'));
          } else {
            router.go(RoutePaths.DASHBOARD_APP);
          }
        }
        break;
      }

      case RouteIds.DASHBOARD_APP:
        firstSelectedInstitution = institutions.find((inst) => inst.institution.selected);
        if (!firstSelectedInstitution && institutions.length === 1) {
          firstSelectedInstitution = institutions[0];
        }
        if (!firstSelectedInstitution) {
          router.go(RoutePaths.INSTITUTIONS);
        } else {
          router.go(`/a/${firstSelectedInstitution.institution.shortId.toString()}`);
        }
        next(false);
        return;

      default:
        break;
    }

    await super.routeEnter(currentNode, nextNodeIfExists, routeId, context, next);
    this.isLoading = false;
  }

  _logout() {
    UserAccessController.logout();
  }

  _toast(event) {
    const toastContainer = this.$.toastContainer;
    const toastElement = document.createElement('jha-toast');
    toastElement.innerHTML = toastMessages[event.detail];
    toastElement.setAttribute('is-visible', true);
    toastContainer.appendChild(toastElement);
    setTimeout(() => {
      toastElement.removeAttribute('is-visible');
    }, 5000);
    setTimeout(() => {
      toastContainer.innerHTML = '';
    }, 5750);
  }

  async _getSession(next) {
    try {
      const session = await AuthController.getSession();
      if (!(session || {}).user) throw new Error('Session invalid.');
      return session;
    } catch (e) {
      console.error('Could not get session details.', e);
      this._redirectToLogout(next);
    }
  }

  async _redirectToLogin(next) {
    router.go(RoutePaths.LOGIN);
    next(false);
    return;
  }

  async _redirectToLogout(next) {
    window.location.href = `/a/login/logout${window.location.search}`;
    if (next) next(false);
  }

  static get template() {
    return html`
    <style>
      :host {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        box-sizing: border-box;
      }
      @media (max-width: 480px) {
        :host {
          padding: 0;
        }
      }
    </style>
    <template is="dom-if" if="[[isLoading]]">
      <jha-progress card=""></jha-progress>
    </template>
    <slot></slot>
    <jha-toast-container id="toastContainer"></jha-toast-container>
  `;
  }
}
customElements.define(DashboardAppElement.is, DashboardAppElement);
export default DashboardAppElement;
