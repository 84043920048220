import ApiPaths from "../api-paths.js";
'use strict';
class LoginApi {
    static sentryLogin() {
        return {
            url: `${ApiPaths.SENTRY_PATH}/login`,
            requestMethods: ['GET', 'POST', 'DELETE']
        };
    }
    static authzLogout() {
        return {
            url: `${ApiPaths.AUTH_PATH}/session/logout`,
            requestMethods: ['POST']
        };
    }
    static setInstitutionId() {
        return {
            url: `${ApiPaths.AUTH_PATH}/session/institution`,
            requestMethods: ['PUT']
        };
    }
    static getPermissions() {
        return {
            url: function (institutionId) {
                return `${ApiPaths.AUTH_PATH}/institutions/${institutionId}`;
            },
            arguments: ['institutionId'],
            requestMethods: ['GET']
        };
    }
    static getSession() {
        return {
            url: `${ApiPaths.AUTH_PATH}/session`,
            requestMethods: ['GET']
        };
    }
    static sentryPasswordReset() {
        return {
            url: `${ApiPaths.SENTRY_PATH}/forgot-password`,
            requestMethods: ['POST']
        };
    }
    static userPermissions() {
        return {
            url: `${ApiPaths.AUTH_PATH}/session/authorize`,
            requestMethods: ['POST']
        };
    }
    static getSessionExpiration() {
        return {
            url: `${ApiPaths.AUTH_PATH}/session/expirations`,
            requestMethods: ['GET']
        };
    }

    static jhidProviders() {
        return {
            url: (email) => `${ApiPaths.JHID_PATH}/v0/providers/${email}`,
            arguments: ['email'],
            requestMethods: ['GET'],
        };
    }
    static jhidExchange() {
        const bffPath = window.location.hostname === 'localhost' ? ApiPaths.BFF_PATH_LOCALHOST : ApiPaths.BFF_PATH;
        return {
            url: `${bffPath}/node-api-gateway/a/jhid/api/v0/banno/enterprise/exchange-token`,
            requestMethods: ['POST'],
        };
    }

    static bffLogout() {
      const bffPath = window.location.hostname === 'localhost' ? ApiPaths.BFF_PATH_LOCALHOST : ApiPaths.BFF_PATH;
      return {
          url: `${bffPath}/bff/logout`,
          requestMethods: ['GET'],
      };
    }
}
export default LoginApi;
