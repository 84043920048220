import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaProgressElement = class extends PolymerElement {
  static get is() {
    return 'jha-progress';
  }

  static get template() {
    return html`
    <style>
      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
      :host {
        display: inline-block;
        animation: spin .75s linear infinite;
        transform-origin: center;
        width: 16px;
        height: 16px;
        border-width: 2px;
        border-style: solid;
        border-color: var(--jha-progress-color, var(--jha-border-color));
        border-right-color: transparent !important;
        border-radius: 50%;
      }
      :host([inline]) {
        width: 12px;
        height: 12px;
      }
      :host([card]) {
        display: block;
        width: 28px;
        height: 28px;
        border-width: var(--jha-progress-card-border-width, 4px);
        margin: 80px auto;
      }
    </style>
  `;
  }
};

customElements.define(window.JhaProgressElement.is, window.JhaProgressElement);
export default window.JhaProgressElement;
