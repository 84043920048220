/**
 * @fileoverview Routing paths.
 * Entries with empty strings are abstract - they are not
 * directly routable. Their callbacks are invoked
 * when a child route is activated.
 */

/** @enum {string} */
const RoutePaths = {
  DASHBOARD_APP: '/a',
  LOGIN: '/a/login',
  LOGIN_LOGOUT: '/a/login/logout',
  LOGIN_COMPLETE: '/a/login/complete',
  LOGIN_XPERIENCE: '/a/login/xperience',
  FORGOT_PASSWORD: '/a/login/forgot-password',
  BROWSER_UPGRADE: '/a/login/browser-upgrade',
  INSTITUTIONS: '/a/login/institutions',
  DASHBOARD: '/a/:institutionShortId([0-9]{1,6})',
  SET_PASSWORD: '/a/login/set-password',
  EULA: '/a/terms-of-use/:mode/:institutionId',
};

export default RoutePaths;