
/**
 * @fileoverview
 *
 * Route tree definition for Assets.
 *
 *          __________________________DASHBOARD_APP____________________________________
 *         /              /                 |                      \                   \
 *  LOGIN-CONTAINER LOGIN-LOGOUT  FORGOT-PASSOWORD-CONTAINER  INSTITUTIONS-CONTAINER  APPS-CONTAINER
 */

import RoutePaths from './paths';
import RouteId from './id';
import RouteData from '@jack-henry/web-component-router/lib/route-data.js';
import RouteTreeNode from '@jack-henry/web-component-router/lib/route-tree-node.js';

const login = new RouteTreeNode(
    new RouteData(RouteId.LOGIN, 'LOGIN-CONTAINER', RoutePaths.LOGIN, [], false, async () => import('../../components/login/login-container.js')));

const loginLogout = new RouteTreeNode(
    new RouteData(RouteId.LOGIN_LOGOUT, 'LOGIN-LOGOUT', RoutePaths.LOGIN_LOGOUT, [], false, async () => import('../../components/login/login-logout.js')));

const oauthLogin = new RouteTreeNode(
    new RouteData(RouteId.LOGIN_COMPLETE, 'LOGIN-COMPLETE', RoutePaths.LOGIN_COMPLETE, [], false, async () => import('../../components/login/login-complete.js')));

const loginXperience = new RouteTreeNode(
    new RouteData(RouteId.LOGIN_XPERIENCE, 'XPERIENCE-TWO', RoutePaths.LOGIN_XPERIENCE, [], false, async () => import('../../components/xperience/xperience-two.js')));

const forgotPassword = new RouteTreeNode(
    new RouteData(RouteId.FORGOT_PASSWORD, 'FORGOT-PASSWORD-CONTAINER', RoutePaths.FORGOT_PASSWORD, [], false, async () => import('../../components/forgot-password/forgot-password-container.js')));

const setPassword = new RouteTreeNode(
    new RouteData(RouteId.SET_PASSWORD, 'SET-PASSWORD-CONTAINER', RoutePaths.SET_PASSWORD, [], false, async () => import('../../components/set-password/set-password-container.js')));

const browserUpgrade = new RouteTreeNode(
    new RouteData(RouteId.BROWSER_UPGRADE, 'BROWSER-UPGRADE', RoutePaths.BROWSER_UPGRADE, [], false, async () => import('../../components/browser-upgrade/browser-upgrade.js')));

const institutions = new RouteTreeNode(
    new RouteData(RouteId.INSTITUTIONS, 'INSTITUTIONS-CONTAINER', RoutePaths.INSTITUTIONS, [], true, async () => import('../../components/institutions/institutions-container.js')));

const dashboardContainer = new RouteTreeNode(
    new RouteData(RouteId.DASHBOARD, 'DASHBOARD-CONTAINER', RoutePaths.DASHBOARD, ['institutionShortId'], true, async () => import('../../components/dashboard/dashboard-container.js')));

const eula = new RouteTreeNode(
    new RouteData(RouteId.EULA, 'EULA-CONTAINER', RoutePaths.EULA, ['mode', 'institutionId'], false, async () => import('../../components/eula/eula-container.js')));

const dashboardApp = new RouteTreeNode(
    new RouteData(RouteId.DASHBOARD_APP, 'DASHBOARD-APP', RoutePaths.DASHBOARD_APP, [], false));

dashboardApp.addChild(login);
dashboardApp.addChild(loginLogout);
dashboardApp.addChild(loginXperience);
dashboardApp.addChild(forgotPassword);
dashboardApp.addChild(setPassword);
dashboardApp.addChild(browserUpgrade);
dashboardApp.addChild(institutions);
dashboardApp.addChild(dashboardContainer);
dashboardApp.addChild(eula);
dashboardApp.addChild(oauthLogin);

export default dashboardApp;
