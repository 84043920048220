import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaToastContainerElement = class extends PolymerElement {
  static get is() {
    return 'jha-toast-container';
  }

  static get template() {
    return html`
    <style>
      :host {
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 1060;
        pointer-events: none;
      }
      @media (min-width: 740px) {
        :host {
          bottom: 32px;
          left: 32px;
        }
      }
      @media (min-width: 415px) {
        :host {
          min-width: var(--jha-toast-min-width, 288px);
          max-width: var(--jha-toast-max-width, 568px);
          bottom: 24px;
          left: 16px;
        }
      }
    </style>

    <slot></slot>
  `;
  }
};

customElements.define(window.JhaToastContainerElement.is, window.JhaToastContainerElement);
export default window.JhaToastContainerElement;
