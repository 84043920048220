import {PolymerElement, html} from '@polymer/polymer/polymer-element.js';
/**
 * @polymer
 * @customElement
 * @extends {PolymerElement}
 */
window.JhaToastElement = class extends PolymerElement {
  static get is() {
    return 'jha-toast';
  }

  static get properties() {
    return {
      role: {
        type: String,
        value: 'alert',
        reflectToAttribute: true,
      },
    };
  }

  static get template() {
    return html`
    <style>
      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: translateY(105%);
        }

        100% {
          opacity: 1;
          transform: translateY(0);
        }
      }
      @keyframes fadeOutDown {
        0% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        100% {
          opacity: 0;
          transform: translate3d(0, 15%, 0);
        }
      }
      :host {
        font-family: 'roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', sans-serif;
        display: flex;
        position: relative;
        contain: content;
        color: #fff;
        background-color: var(--jha-toast-background, var(--jha-color-dark, #455564));
        border-radius: var(--jha-toast-border-radius, 0px);
        margin-bottom: 8px;
        padding: 12px 24px;
        opacity: 0;
        transform: translateY(105%);
        transition: all cubic-bezier(0.1, 0.5, 0.1, 1) 0.75s;
        will-change: opacity, transform;
      }
      :host([danger]) {
        background-color: var(--jha-color-danger, #f44336);
      }
      :host([is-visible]) {
        animation-name: fadeInUp;
        animation-duration: 0.75s;
        animation-timing-function: cubic-bezier(0.1, 0.5, 0.1, 1);
        animation-delay: initial;
        animation-iteration-count: 1;
        animation-direction: initial;
        animation-fill-mode: backwards;
        animation-play-state: initial;
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
        transition: opacity, transform cubic-bezier(0.1, 0.5, 0.1, 1) 0.75s;
        will-change: opacity, transform;
      }
      :host ::slotted(.icon) {
        fill: #fff;
        margin-right: 10px;
      }
      :host ::slotted(div) {
        --jha-button-background: #fff;
        --jha-button-link-font-weight: 700;
      }
      @media (min-width: 415px) {
        :host {
          border-radius: var(--jha-toast-border-radius, 2px);
        }
      }
      @media (min-width: 740px) {
        :host {
        }
      }
    </style>

    <slot></slot>

  `;
  }
};

customElements.define(window.JhaToastElement.is, window.JhaToastElement);
export default window.JhaToastElement;
