'use strict';
const ApiPaths = {
    CMS_PATH: '/a/cms/api',
    CONSUMER_PATH: '/a/consumer/api',
    CONVERSATIONS_PATH: '/a/conversations/api',
    JHID_PATH: '/a/jhid/api',
    FORMS_PATH: '/a/forms/api',
    MAILBOXES_PATH: '/a/mailboxes/api',
    MOBILE_PATH: '/a/mobile/api',
    SENTRY_PATH: '/a/sentry/api',
    AUTH_PATH: '/a/auth/api',
    BASE_PATH: '/a/api',
    BFF_PATH: '/banno-enterprise-bff',
    BFF_PATH_LOCALHOST: '/banno-enterprise-bff-localhost',
};
export default ApiPaths;
